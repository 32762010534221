<template>
	<div>
		<v-icon
			link
			class="input-addon primary--text"
			:class="[{ 'mt-5': marginTop }]"
			v-on:click="drawer = !drawer"
		>
			mdi-cog
		</v-icon>
		<v-navigation-drawer
			class="productUnitDrawer"
			v-model="drawer"
			app
			stateless
			right
			width="480px"
			content-class="nav-separation-from-modal"
		>
			<v-card class="" tile elevation="0">
				<v-card-title class="flex py-3 d-flex border-bottom drawer-header">
					<div class="font-level-3-bold">{{ title }}</div>
					<v-spacer></v-spacer>
					<v-btn
						tile
						depresed
						color="white--text"
						class="blue darken-4 mr-2"
						:disabled="!formValid || pageLoading"
						v-on:click="addSetting()"
					>
						Save
					</v-btn>
					<v-btn tile depresed class="darken-4" v-on:click="drawer = false"> Close </v-btn>
				</v-card-title>
				<v-card-text class="d-flex px-4">
					<v-row class="mt-2">
						<v-col
							md="12"
							cols="12"
							class="my-auto py-0 pr-12 mb-2 position-relative service-provider border rounded bg-light"
							v-for="(data, index) in create_setting"
							:key="index"
						>
							<v-form
								ref="extendReasonForm"
								v-model.trim="formValid"
								v-on:submit.stop.prevent="addSetting"
							>
								<TextInput
									style="margin-bottom: 8px !important"
									hide-details
									:disabled="pageLoading"
									:loading="pageLoading"
									v-model="create_setting[index].text"
									:rules="[vrules.required(create_setting[index].text, 'Reason')]"
									placeholder="Reason"
									:class="{
										required: !create_setting[index].text,
									}"
								>
								</TextInput>

								<template v-if="data.id">
									<v-icon
										v-if="create_setting.length > 1"
										class="red--text cursor-pointer"
										style="position: absolute; top: 14px; right: 14px"
										v-on:click="deleteSetting(data.id)"
										>mdi-delete
									</v-icon>
								</template>
								<template v-else>
									<v-icon
										v-if="create_setting.length > 1"
										class="red--text cursor-pointer"
										style="position: absolute; top: 14px; right: 14px"
										v-on:click="deletefield(index)"
										>mdi-delete
									</v-icon>
								</template>
							</v-form>
						</v-col>
						<v-col>
							<v-btn tile depressed color="blue darken-4" class="white--text" v-on:click="addField">
								<v-icon>mdi-plus</v-icon>
								Add
							</v-btn>
						</v-col>
					</v-row>
				</v-card-text>
			</v-card>
		</v-navigation-drawer>
	</div>
</template>

<style>
.nav-separation-from-modal {
	z-index: 9999 !important;
}
</style>

<script>
import TextInput from "@/view/components/TextInput";
// import SelectInput from "@/view/components/SelectInput";
import { POST, GET, DELETE } from "@/core/services/store/request.module";
import { SET_ERROR, SET_MESSAGE } from "@/core/services/store/common.module";
import MainMixin from "@/core/mixins/main.mixin.js";
export default {
	name: "Create-Service-Provider",
	mixins: [MainMixin],
	props: {
		title: {
			type: String,
			default: null,
		},
		typeId: {
			default: 0,
		},
		marginTop: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			drawer: false,
			pageLoading: false,
			val: null,
			formValid: true,
			create_setting: [
				{
					text: null,
					// provider_type: "1",
					// services: {
					// 	seo: 1,
					// 	hosting: 0,
					// 	domain: 0,
					// },
					// status_color: "green",
				},
			],

			provider_types: [
				{
					title: "Company",
					value: "1",
				},
				{
					title: "Client",
					value: "2",
				},
			],
			services_array: [
				{
					title: "SEO",
					value: "seo",
				},
				{
					title: "Hosting",
					value: "hosting",
				},
				{
					title: "Domain",
					value: "domain",
				},
			],
		};
	},
	methods: {
		addField() {
			this.create_setting.push({
				text: null,
				provider_type: "1",
				services: {
					seo: 1,
					hosting: 0,
					domain: 0,
				},
				status_color: "green",
			});
		},
		deletefield(index) {
			this.create_setting.splice(index, 1);
			this.$refs["extendReasonForm"].validate();
		},
		setProviderType(index, p_type) {
			if (p_type == "1") {
				this.create_setting[index].services = {
					seo: 1,
					hosting: 0,
					domain: 0,
				};
			} else {
				this.create_setting[index].services = null;
			}
		},
		addSetting() {
			const _this = this;

			if (!this.$refs.extendReasonForm[0].validate()) {
				return false;
			}

			const new_create_setting = _this.create_setting.reduce((uniq, curr_val) => {
				if (!uniq.some((item) => item.text == curr_val.text)) {
					uniq.push(curr_val);
				}
				return uniq;
			}, []);

			const found = new_create_setting.findIndex((v) => !v.text);

			if (found > -1) {
				this.$store.commit(SET_ERROR, [{ model: true, message: `${this.title} field is required.` }]);
				return false;
			}

			_this.pageLoading = true;
			this.$store
				.dispatch(POST, {
					url: "lead-setting-update",
					data: {
						type: this.typeId,
						status: new_create_setting,
					},
				})
				.then(() => {
					this.drawer = false;

					this.getSetting();
					this.$store.commit(SET_MESSAGE, [
						{
							model: true,
							message: "Success ! Setting updated successfully.",
						},
					]);
				})
				.catch((error) => {
					console.log({
						error,
					});
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		getSetting() {
			this.pageLoading = true;
			const _this = this;
			_this.$store
				.dispatch(GET, {
					url: `single-setting/${this.typeId}`,
				})
				.then((data) => {
					if (data && Array.isArray(data) && data.length) {
						let _data = [];
						data.forEach((p) => {
							let obj = { ...p, services: { seo: p.seo, domain: p.domain, hosting: p.hosting } };
							_data.push(obj);
						});
						this.create_setting = [..._data];
						this.$emit("success", data);
					}
				})
				.catch((error) => {
					console.log({
						error,
					});
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		deleteSetting(id) {
			this.pageLoading = true;
			this.$store
				.dispatch(DELETE, {
					url: `lead-setting/${id}`,
				})
				.then(() => {
					this.getSetting();
					this.$refs["extendReasonForm"].validate();
				})
				.catch((error) => {
					console.log({
						error,
					});
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
	},
	components: {
		TextInput,
		// SelectInput,
	},
	mounted() {
		this.getSetting();
	},
};
</script>
