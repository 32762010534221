<template>
	<div>
		<v-navigation-drawer v-model="drawer" fixed width="600" right temporary stateless>
			<div class="drawer-wrapper">
				<v-flex class="py-3 px-5 d-flex border-bottom drawer-header">
					<div class="font-level-3-bold">Activity</div>
					<v-spacer></v-spacer>
					<v-btn depressed tile class="mr-2" v-on:click="$emit('close', true)">Cancel</v-btn>
					<v-btn tile depressed color="white--text" class="blue darken-4">Save</v-btn>
				</v-flex>
				<div class="drawer-content pt-5 px-5">
					<div>
						<div class="py-3">
							<div class="fs-16 fw-500 primary--text">Upcoming</div>
						</div>
						<div>
							<ActivityTab :open_details="true"></ActivityTab>
						</div>
						<div>
							<ActivityTab></ActivityTab>
						</div>
					</div>
				</div>
			</div>
		</v-navigation-drawer>
	</div>
</template>
<script>
import ActivityTab from "@/view/pages/leads/components/ActivityTab";
export default {
	name: "ActivityLog",
	props: {
		drawer: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {};
	},
	components: {
		ActivityTab,
	},
};
</script>
<style scoped></style>
