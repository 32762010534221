<template>
	<v-row justify="center">
		<v-dialog
			origin="top center"
			v-model="dialog"
			scrollable
			:hide-overlay="hideOverlay"
			:content-class="`${contentClass} common-dialog`"
			:max-width="dialogWidth"
			:retain-focus="retainFocus"
			@click:outside="$emit('close', true)"
			@keydown.escape="$emit('close', true)"
			:persistent="persistent"
		>
			<v-card>
				<v-card-title
					v-if="$slots.title"
					:class="[
						'headline dialog-custom-header text-uppercase custom-header-blue-text white-background',
						{ 'custom-border-bottom': !$slots.subtitle },
					]"
				>
					<slot name="title"></slot>
				</v-card-title>

				<v-card-title
					v-if="$slots.subtitle"
					:class="['blue-text white-background', { 'custom-border-bottom': $slots.subtitle }]"
				>
					<slot name="subtitle"></slot>
				</v-card-title>

				<v-card-text
					v-if="$slots.body"
					:class="[{ 'px-8 py-4': !dense, 'px-3 py-0': dense }, bodyClass]"
				>
					<slot name="body"></slot>
				</v-card-text>
				<v-divider class="my-0"></v-divider>
				<v-card-actions v-if="$slots.action">
					<v-spacer></v-spacer>
					<slot name="action"></slot>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</v-row>
</template>

<style scoped>
.v-dialog__content {
	align-items: start;
}
</style>

<script>
export default {
	props: {
		dialog: {
			type: Boolean,
			required: true,
			default: false,
		},
		dense: {
			type: Boolean,
			required: false,
		},
		dialogWidth: {
			type: Number,
			default: 768,
		},
		contentClass: {
			type: String,
			default: null,
		},
		retainFocus: {
			type: Boolean,
			default: true,
		},
		persistent: {
			type: Boolean,
			default: false,
		},
		hideOverlay: {
			type: Boolean,
			default: false,
		},
		bodyClass: {
			type: [String, Object],
			default: () => {},
		},
	},
	data() {
		return {
			// persistent: true,
		};
	},
	methods: {},
};
</script>
