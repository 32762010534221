<template>
	<v-sheet class="main-detail">
		<v-row>
			<v-col md="12">
				<v-row>
					<v-col
						cols="4"
						v-for="(project, index) in viewProjects['project_views']"
						:key="`_view_${index}`"
					>
						<v-card>
							<v-card-actions class="py-0 px-1">
								<v-card-title>{{ project.project_name }}</v-card-title>
								<v-spacer />
								<v-avatar :color="project.status" size="36">
									<span class="white--text">{{ project.total_ranking }}</span>
								</v-avatar>
							</v-card-actions>
							<v-card-subtitle>Learn Web Development In Chunks</v-card-subtitle>
							<v-card-actions class="py-0">
								<h5 style="font-weight: 700" color="blue">{{ project.project_avg }}</h5>
							</v-card-actions>
							<div>
								<v-progress-linear
									v-model="value"
									:buffer-value="project.bufferValue"
									:color="project.status"
								></v-progress-linear>
							</div>
						</v-card>
					</v-col>
				</v-row>
			</v-col>
			<v-col cols="4">
				<v-card>
					<div class="project-overview-dashboard">
						<v-layout class="px-4 py-2 border-bottom">
							<div class="my-auto font-level-3-bold fw-600">Project Overview</div>
							<v-spacer></v-spacer>
							<v-select
								class="cm-menu-select elevation-0 red--text"
								v-model="project_overview"
								:items="projectFilterList"
								hide-details
								dense
								solo
							>
								<template v-slot:selection="{ item }">
									<span class="blue--text font-level-3-bold">{{ item }}</span>
								</template>
							</v-select>
						</v-layout>
						<v-layout>
							<v-flex class="border py-5 text-center">
								<div class="fs-24 fw-600">16</div>
								<div class="grey--text">Number of Projects</div>
							</v-flex>
							<v-flex class="border py-5 text-center">
								<div class="fs-24 fw-600">3</div>
								<div class="grey--text">Active Project</div>
							</v-flex>
							<v-flex class="border py-5 text-center">
								<div class="fs-24 fw-600">$1680 k</div>
								<div class="grey--text">Revenue</div>
							</v-flex>
							<v-flex class="border py-5 text-center">
								<div class="fs-24 fw-600 green--text">7,980hrs</div>
								<div class="grey--text">Working Hours</div>
							</v-flex>
						</v-layout>
						<div class="mx-auto px-4 py-3">
							<div>
								<apexchart
									type="line"
									height="450"
									:options="chartOptions"
									:series="series"
									width="100%"
								></apexchart>
							</div>
							<div class="project-desc">
								<ul class="pa-0 ul">
									<li class="d-flex li" v-for="(row, index) in chartData" :key="index">
										<div class="d-flex">
											<div :class="`status_color mr-3 ${row.status_color}`"></div>
											<div class="name text-truncate">{{ row.status }}</div>
										</div>
										<div class="project-number text-right">{{ row.order_no }}</div>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</v-card>
			</v-col>
		</v-row>
		<div>
			<v-layout class="section-main-content">
				<div class="details-body">
					<div class="p-sticky bg-white">
						<v-layout class="pa-2 pb-0">
							<v-tabs
								v-model="projectTab"
								background-color="transparent"
								color="blue"
								class="custom-tab-transparent w-100"
								active-class="blue darken-4 text-white"
								hide-slider
							>
								<v-tab href="#overview"><v-icon small left>mdi-information-outline</v-icon>Overview</v-tab>
								<v-tab href="#ranking1"><v-icon small left>mdi-google-analytics</v-icon>Ranking</v-tab>
								<v-tab href="#analytics_frame"
									><v-icon small left>mdi-check-circle-outline</v-icon>Analytics</v-tab
								>
							</v-tabs>
						</v-layout>
					</div>
					<div class="tab-layout-content project-tb-content px-4">
						<v-tabs-items v-model="projectTab">
							<v-tab-item
								v-if="projectTab == 'overview'"
								value="overview"
								class="d-flex justify-space-between"
							>
								<Overview :credentialStatus="credentialStatus" />
							</v-tab-item>
							<v-tab-item
								v-if="projectTab == 'ranking1'"
								value="ranking1"
								class="d-flex justify-space-between"
							>
								<DomainRankingTab />
							</v-tab-item>

							<v-tab-item v-if="projectTab == 'analytics_frame'" value="analytics_frame">
								<Analytics></Analytics>
							</v-tab-item>
							<v-tab-item value="activity">
								<div>
									<div v-for="(logs, index) in logData" :key="index">
										<ActivityTab :open_details="true" :log="logs" title="Project"></ActivityTab>
									</div>
								</div>
							</v-tab-item>
						</v-tabs-items>
					</div>
				</div>
			</v-layout>
			<v-file-input ref="uploadFile" class="d-none" v-on:change="uploadFile($event)"></v-file-input>
		</div>

		<ActivityLog
			:drawer="open_dialog.activity_log"
			v-on:close="open_dialog.activity_log = false"
		></ActivityLog>
		<CancelProject
			v-if="cancelDialog"
			v-on:close="cancelDialog = false"
			:cancel-dialog="cancelDialog"
			:project-id="projectId"
			v-on:success="(cancelDialog = false), getSingleProject()"
		>
		</CancelProject>
	</v-sheet>
</template>
<style src="@/assets/sass/sprite.scss" lang="scss"></style>
<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { HostingEventBus } from "@/core/lib/hosting.lib";
import ActivityLog from "@/view/module/components/ActivityLog";
import { GET } from "@/core/services/store/request.module";
import { toSafeInteger, filter } from "lodash";
import ActivityTab from "@/view/pages/leads/components/ActivityTab";
import CancelProject from "@/view/module/project/Cancel-Project";
import Overview from "@/view/module/seo/Tabs/Overview-SEO";
import DomainRankingTab from "@/view/module/domain/DomainRankingTab";
import Analytics from "@/view/module/domain/Analytics";
import { SET_MESSAGE } from "@/core/services/store/common.module";
export default {
	name: "Leads-details",
	data() {
		return {
			project_overview: "All Time",
			projectFilterList: ["All Time", "This Week", "Last Week", "This Month", "Last Month"],
			series: [
				{
					name: "Number of Projects",
					type: "column",
					data: [23, 11, 42, 27, 43, 22, 37, 21, 44, 22, 30, 12],
				},
				{
					name: "Revenue",
					type: "area",
					data: [44, 55, 41, 67, 62, 43, 41, 71, 56, 47, 43, 33],
				},
				{
					name: "Active Project",
					type: "column",
					data: [30, 25, 36, 30, 23, 35, 34, 52, 29, 26, 29, 44],
				},
			],
			chartData: [],
			chartOptions: {
				chart: {
					height: 450,
					type: "line",
					stacked: false,
				},
				stroke: {
					width: [0, 2, 5],
					curve: "smooth",
				},
				plotOptions: {
					bar: {
						columnWidth: "30%",
					},
				},

				fill: {
					opacity: [0.85, 0.25, 1],
					gradient: {
						inverseColors: false,
						shade: "light",
						type: "vertical",
						opacityFrom: 0.85,
						opacityTo: 0.55,
						stops: [0, 100, 100, 100],
					},
				},
				labels: [
					"01/01/2003",
					"02/01/2003",
					"03/01/2003",
					"04/01/2003",
					"05/01/2003",
					"06/01/2003",
					"07/01/2003",
					"08/01/2003",
					"09/01/2003",
					"10/01/2003",
					"11/01/2003",
				],
				markers: {
					size: 0,
				},
				xaxis: {
					type: "datetime",
				},
				yaxis: {
					title: {
						text: "Points",
					},
					min: 0,
				},
				tooltip: {
					shared: true,
					intersect: false,
					y: {
						formatter: function (y) {
							if (typeof y !== "undefined") {
								return y.toFixed(0) + " points";
							}
							return y;
						},
					},
				},
			},
			credentialStatus: false,
			value: 30,
			interval: 0,
			viewProjects: {
				project_views: [
					{
						project_name: "Business",
						total_ranking: "10",
						total_analytics: "10",
						project_avg: "10%",
						status: "blue",
						bufferValue: 100,
					},
					{
						project_name: "Business",
						total_ranking: "6",
						total_analytics: "1",
						project_avg: "10%",
						status: "orange",
						bufferValue: 100,
					},
					{
						project_name: "Business",
						total_ranking: "3",
						total_analytics: "14",
						project_avg: "10%",
						status: "green",
						bufferValue: 100,
					},
				],
				project_overviews: [
					{
						title: "Number of Projects",
						noOfProject: "16",
					},
					{
						title: "Active Project",
						noOfProject: "3",
					},
					{
						title: "Revenue",
						noOfProject: "$1680 k",
					},
					{
						title: "Working Hours",
						noOfProject: "7,980hrs",
						status: "fs-24 fw-600 green--text",
					},
				],
			},
			search_open: false,
			project: {},
			open_detail: true,
			cancelDialog: false,
			customer: {},
			clientData: false,
			customer_hosting: [],
			customer_services: [],
			customer_ssl: [],
			customer_web: [],
			salesPersonList: {},
			logData: [],
			contract: {},
			maintenance: [],
			domain: [],

			projectId: 0,
			total_invoice: 0,
			open_dialog: {
				task: false,
				reminder: false,
				create_project: false,
				activity_log: false,
			},
			panel: [0],
			open_details: false,
			assign_note: "Add Description",
			file_list: [],
		};
	},
	components: {
		DomainRankingTab,
		ActivityTab,
		CancelProject,
		Overview,
		ActivityLog,
		Analytics,
	},
	methods: {
		cancelProject() {
			this.cancelDialog = true;
		},
		routeToUpdate(id) {
			this.$router.push({
				name: "update-project",
				params: { id },
				query: { t: new Date().getTime() },
			});
		},
		renewedProject(id) {
			this.projectId = id;
			this.getSingleProject();
			this.$router.push({
				name: "project-detail",
				params: { id },
				query: { t: new Date().getTime() },
			});
		},
		copyNumber(data) {
			navigator.clipboard.writeText(data);
			this.$store.commit(SET_MESSAGE, [
				{ model: true, message: `<b>${data}</b> - successfully coppied.` },
			]);
		},
		routeToRenew(id) {
			this.$router.push({
				name: "create-renewed-project",
				query: { t: new Date().getTime(), project_renew: toSafeInteger(id) },
			});
		},
		invoiceCount(count) {
			this.total_invoice = count;
		},
		pageTitle() {
			return "Lead Details";
		},
		uploadFile(data) {
			const file_size = (data.size / 1024).toFixed(1) + "kb";
			const data_opj = { icon: "mdi-file-check-outline", name: data.name, size: file_size };
			this.file_list.push(data_opj);
		},
		selectFile() {
			this.$refs["uploadFile"].$refs["input"].click();
		},
		remove_file(index) {
			this.file_list.splice(index, 1);
		},
		taskActivity() {
			this.$store
				.dispatch(GET, {
					url: `project-log/${this.projectId}`,
				})
				.then((data) => {
					this.logData = data;
				})
				.catch((error) => {
					console.log({ error });
				});
		},

		getSingleProject() {
			this.$store
				.dispatch(GET, {
					url: `single-project/${this.projectId}`,
				})
				.then((data) => {
					this.project = data.project;
					this.customer = data.customer;
					this.salesPersonList = data.attendie[0];
					this.customer_hosting = data.customer_hosting;
					let accept = filter(this.customer_hosting, (row) => row.type == "client");
					if (accept.length > 0) {
						this.clientData = true;
					}
					this.customer_services = data.customer_services;
					this.customer_ssl = data.customer_ssl;
					this.customer_web = data.customer_web;
					this.domain = data.domain;
					this.maintenance = data.maintenance;
					this.contract = data.contract;
					this.total_invoice = data.total_invoice;
				})
				.catch((error) => {
					console.log({
						error,
					});
				});
		},
		projectActivityfun() {
			this.$store
				.dispatch(GET, { url: `project-activity/${this.projectId}` })
				.then((data) => {
					this.projectData = data;
				})
				.catch((error) => {
					console.log({ error });
				});
		},
	},
	beforeMount() {
		this.projectId = toSafeInteger(this.$route.params.id);
	},
	computed: {
		projectTab: {
			set(val) {
				let query = { ...this.$route.query };
				query.tab = val;
				if (val != this.projectTab) {
					this.$router.replace({ query });
				}
			},
			get() {
				return this.$route.query.tab || "overview";
			},
		},
	},
	mounted() {
		if (!this.getPermission("project:view") && this.projectId > 0) {
			this.$router.replace({
				name: "project",
				query: { t: new Date().getTime() },
			});
		}
		this.getSingleProject();
		this.taskActivity();
		this.$store.dispatch(SET_BREADCRUMB, [
			{ text: "Seo", disabled: false, href: "/seo" },
			{ text: "Detail", disabled: true, href: "/seo-detail/1" },
		]);
		HostingEventBus.$on("refresh", () => {
			this.getSingleProject();
		});
	},
};
</script>
