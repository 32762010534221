<template>
	<v-autocomplete
		:id="id"
		:label="label"
		:loading="loading"
		:disabled="disabled"
		:items="items"
		:rules="rules"
		:prepend-inner-icon="prependInnerIcon"
		:append-icon="appendIcon"
		:append-outer-icon="appendOuterIcon"
		:item-text="itemText"
		:item-value="itemValue"
		:hide-details="hideDetails"
		:return-object="returnObject"
		outlined
		:readonly="readonly"
		:multiple="multiple"
		:dense="dense"
		:clearable="clearable"
		:class="
			({
				'mt-3 pt-0': !hideTopMargin,
				'dense-autocomplete': dense,
				'time-picker-autocomplete': timePicker,
			},
			customClass)
		"
		:menu-props="menu_Props"
		v-model="autocompleteinput"
		:placeholder="getPlaceholder()"
		@change="$emit('change', autocompleteinput)"
		@click:append-outer="$emit('click:append-outer', true)"
		:style="inlineStyle"
	>
		<template #no-data>
			<v-list-item>
				<v-list-item-title v-html="`No ${placeholder} Found.`"></v-list-item-title>
			</v-list-item>
		</template>
		<template v-if="$scopedSlots.noStyleItem" #item="{ parent, item, on, attrs }">
			<slot name="noStyleItem" :li="item" :attrs="attrs">{{ item }}</slot>
		</template>

		<template
			v-if="$scopedSlots.selectedItem"
			#selection="{ parent, item, index, select, selected, disabled }"
		>
			<slot name="selectedItem" :item="item" :index="index">{{ item }}</slot>
		</template>
	</v-autocomplete>
</template>
<script>
import { cloneDeep } from "lodash";

export default {
	name: "autocomplete-input",
	model: {
		prop: "value",
		event: "change",
	},
	props: {
		value: {
			type: [Object, Number, String, Array],
			default: null,
		},
		multiple: {
			type: Boolean,
			default: false,
		},
		noStyleList: {
			type: Boolean,
			default: false,
		},
		clearable: {
			type: Boolean,
			default: false,
		},
		readonly: {
			type: Boolean,
			default: false,
		},
		hideTopMargin: {
			type: Boolean,
			default: false,
		},
		customClass: {
			type: [String, Object],
			default: () => {},
		},
		inlineStyle: {
			type: [Object, String],
			default: () => {},
		},
		items: {
			type: Array,
			required: true,
			default: () => {
				return [];
			},
		},
		rules: {
			type: [Array, Object],
			default: () => {
				return [];
			},
		},
		prependInnerIcon: {
			type: String,
			default: null,
		},
		appendIcon: {
			type: String,
			default: "mdi-menu-down",
		},
		appendOuterIcon: {
			type: String,
			default: null,
		},
		placeholder: {
			type: String,
			default: null,
		},
		id: {
			type: String,
			default: null,
		},
		label: {
			type: String,
			default: null,
		},
		itemText: {
			type: String,
			default: "text",
		},
		itemValue: {
			type: String,
			default: "value",
		},
		dense: {
			type: Boolean,
			default: false,
		},
		hideDetails: {
			type: Boolean,
			default: false,
		},
		timePicker: {
			type: Boolean,
			default: false,
		},
		loading: {
			type: Boolean,
			default: false,
		},
		returnObject: {
			type: Boolean,
			default: false,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		menuProps: {
			type: Object,
			default: () => {},
		},
	},
	watch: {
		value: {
			deep: true,
			immediate: true,
			handler(value) {
				this.autocompleteinput = value;
			},
		},
	},
	data() {
		return {
			autocompleteinput: null,
			// menuProps: {},
		};
	},
	methods: {
		getPlaceholder() {
			return `Select ${this.placeholder}`;
		},
	},
	computed: {
		menu_Props: {
			get() {
				const _menuprops = cloneDeep(this.menuProps) || new Object();
				if (Object.keys(_menuprops).length) {
					if (this.dense) {
						_menuprops.contentClass = `${this.menuProps.contentClass} dense-autocomplete-list`;
					}
					if (this.timePicker) {
						_menuprops.contentClass = `${this.menuProps.contentClass} time-picker-autocomplete-list`;
					}
				}
				return _menuprops;
			},
		},
	},
	mounted() {
		this.autocompleteinput = this.value;
		/* if (this.dense) {
			this.menuProps.contentClass = "dense-autocomplete-list";
		}
		if (this.timePicker) {
			this.menuProps.contentClass = "time-picker-autocomplete-list";
		} */
	},
};
</script>
