<template>
	<v-menu
		ref="datepicker"
		v-model="datepicker"
		:close-on-content-click="false"
		transition="scale-transition"
		offset-y
		max-width="290px"
		min-width="auto"
		:content-class="contentClass"
		:nudge-left="nudgeLeft"
	>
		<template #activator="{ on, attrs }">
			<v-text-field
				:id="_id"
				:label="label"
				:loading="loading"
				clearable
				:disabled="disabled"
				:outlined="variant == 'outlined'"
				:solo="variant == 'solo'"
				class="pt-0 filterSelect fw-600"
				:class="[customClass]"
				:style="inlineStyle"
				v-model="dateFormatted"
				:placeholder="btxPlaceholder"
				hide-details
				prepend-inner-icon="mdi-calendar"
				v-bind="attrs"
				v-on:blur="date = parseDateArray(dateFormatted)"
				v-on="on"
				readonly
				@click:clear="clearDate"
				@input="$emit('input', $event)"
			></v-text-field>
		</template>
		<v-date-picker
			:id="id + '_dp'"
			v-model="date"
			no-title
			range
			dense
			reactive
			:min="minDate"
			:type="type"
			:max="maxDate"
		>
			<template #default>
				<!-- Don't remove without permission -->
				<div style="user-select: none" class="d-flex justify-center w-100">
					<img alt="Logo" src="/media/logos/bthrust.png" width="100" />
				</div>
			</template>
		</v-date-picker>
		<!-- <v-date-picker
			v-model="date"
			no-title
			range
			:min="minDate"
			:allowed-dates="disablePastDates"
			:max="maxDate"
		></v-date-picker> -->
	</v-menu>
</template>

<script>
import { last, head } from "lodash";
import moment from "moment";
export default {
	name: "date-range-picker-v2",
	model: {
		prop: "value",
		event: "input",
	},
	props: {
		value: {
			type: [Array, String],
			default: null,
		},
		variant: {
			type: String,
			default: "outlined",
		},
		contentClass: {
			type: String,
			default: undefined,
		},
		inlineStyle: {
			type: Object,
			default: () => {},
		},
		minDate: {
			type: [Date, String],
			default: null,
		},
		maxDate: {
			type: [Date, String],
			default: null,
		},
		placeholder: {
			type: String,
			default: "Date",
		},
		id: {
			type: [String, Number],
			default: null,
		},
		label: {
			type: String,
			default: null,
		},
		loading: {
			type: Boolean,
			default: false,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		clear: {
			type: Number,
			default: 0,
		},
		/* disablePastFrom: {
			type: String,
		}, */
		nudgeLeft: {
			type: [String, Number],
			default: 0,
		},
		customClass: {
			type: [String, Object],
			default: () => {},
		},
		type: {
			type: String,
			default: "date",
		},
	},
	data() {
		return {
			date: [],
			datepicker: false,
			dateFormatted: null,
			stopEmit: false,
		};
	},
	watch: {
		minDate() {
			this.date = [];
		},
		maxDate() {
			this.date = [];
		},
		clear(param) {
			if (param) {
				this.date = null;
				this.$emit("clearDates", false);
			}
		},
		date() {
			const _formatted_date = this.emitDates(this.date);
			this.dateFormatted = this.formatDate(_formatted_date);
			if (this.type == "month") {
				this.$emit("input", this.dateFormatted);
				this.$emit("change", this.dateFormatted);
			} else {
				this.$emit("input", _formatted_date);
				this.$emit("change", _formatted_date);
			}
		},
		value: {
			deep: true,
			handler(param) {
				if (param == null || !param.length) {
					this.dateFormatted = null;
					this.date = null;
					/* this.$emit("click:clear", true); */
				}
			},
		},
	},
	methods: {
		formatDate(dates) {
			if (!dates) return null;
			if (this.type == "month") {
				const start = dates.length && dates[0] ? dates[0] : "";
				const end = dates.length && dates[1] ? dates[1] : "";
				if (Boolean(start) && Boolean(end)) {
					const startDate = this.MOMENTJS(start).format("MMM YYYY") || head(dates);
					const endDate = this.MOMENTJS(end).format("MMM YYYY") || last(dates);
					return startDate + " - " + endDate;
				} else {
					return "";
				}
			} else {
				const startDate = head(dates);
				const endDate = last(dates);
				const data = this.splitDate(startDate) + " - " + this.splitDate(endDate);
				return startDate && endDate ? data : null;
			}
		},
		splitDate(date) {
			if (!date) return null;
			const [year, month, day] = date.split("-");
			return `${day}/${month}/${year}`;
		},
		parseDateArray(dates) {
			if (!dates) return [];
			const dateArray = dates.split(" - ");
			const startDate = head(dateArray);
			const endDate = last(dateArray);
			if (this.type == "date") {
				return [this.parseDate(startDate), this.parseDate(endDate)];
			}
		},
		parseDate(date) {
			if (date && date != "null") {
				const [day, month, year] = date.split("/");
				return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
			}
			return null;
		},
		formatDateYYYYMMDD(date) {
			const _date = new Date(date);
			return moment(_date).format("YYYY-MM-DD");
		},
		emitDates(date) {
			if (!date) return [];
			const start = head(date);
			const end = last(date);
			if (start && end) {
				let startDate = new Date(start).getTime();
				let endDate = new Date(end).getTime();

				if (startDate < endDate) {
					return [this.formatDateYYYYMMDD(startDate), this.formatDateYYYYMMDD(endDate)];
				} else if (startDate > endDate) {
					return [this.formatDateYYYYMMDD(endDate), this.formatDateYYYYMMDD(startDate)];
				} else {
					return [this.formatDateYYYYMMDD(startDate), this.formatDateYYYYMMDD(endDate)];
				}
			}
			return [];
		},
		clearDate() {
			this.date = null;
			this.$emit("click:clear", true);
		},
		/* resetDate function is called from parent components  */
		resetDate() {
			this.date = [];
		},
		/* disablePastDates(val) {
			return this.disablePastFrom
				? val >= new Date(this.disablePastFrom).toISOString().substr(0, 10)
				: new Date();
		}, */
	},
	computed: {
		bthrustLogo() {
			return process.env.BASE_URL + "/media/logos/bthrust.png";
		},
		btxPlaceholder() {
			return this.placeholder + " [DD/MM/YYYY] - [DD/MM/YYYY]";
		},
		_id: {
			get() {
				return this.id ? (this.id || +new Date()).toString() : undefined;
			},
		},
	},
	mounted() {
		this.date = this.value;
	},
};
</script>
