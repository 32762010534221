<template>
	<Dialog :dialog="CancelDialog" :dialog-width="900" style="z-index: 888 !important">
		<template v-slot:title>Cancel Project</template>
		<template v-slot:body>
			<v-form
				ref="extendCheckoutForm"
				v-model.trim="formValid"
				lazy-validation
				v-on:submit.stop.prevent="onSubmit"
			>
				<!-- <v-row class="mb-3">
					<v-col md="12" class="py-0">
						<v-radio-group row hide-details class="mt-0">
							<v-radio class="custome-radio" value="single"></v-radio>
							<v-chip color="primary" label outlined x-small class="ml-2" style="margin-right: 90px">
								Extend this invoice
							</v-chip>
							<v-radio class="custome-radio" value="multiple"></v-radio>
							<v-chip color="red" label outlined x-small class="ml-2">
								Extend all upcoming invoice
							</v-chip>
						</v-radio-group>
					</v-col>
				</v-row> -->
				<v-row class="mb-3">
					<v-col md="3" class="py-0">
						<label for="" class="required">Reason</label>
					</v-col>
					<v-col md="9" class="my-auto py-0 pr-10 position-relative">
						<v-autocomplete
							:items="reasonList"
							item-text="text"
							item-value="value"
							hide-details
							outlined
							v-model="cancelProject.reason"
							placeholder="Reason"
							:rules="[vrules.required(cancelProject.reason, 'Reason')]"
							:class="{
								required: !cancelProject.reason,
							}"
						>
						</v-autocomplete>
						<ExtendReasons
							:title="'Cancel Reason'"
							:type-id="61"
							v-on:success="getSettings(), (cancelProject.reason = null)"
						></ExtendReasons>
						<!-- <v-autocomplete :items="reasonList" item-text="text" item-value="value" v-model="extention.reason"
							hide-details outlined placeholder="Extend Period">
						</v-autocomplete> -->
					</v-col>
				</v-row>
				<v-row class="mb-3">
					<v-col md="3" class="py-0">
						<label for="" class="required">Remark</label>
					</v-col>
					<v-col md="9" class="py-0">
						<TextAreaInput
							dense
							hide-details
							placeholder="Remark"
							v-model="cancelProject.remark"
							:word-limit="250"
							:rows="5"
							:rules="[vrules.required(cancelProject.remark, 'Remark')]"
							:customClass="{
								required: !cancelProject.remark,
							}"
						></TextAreaInput>
					</v-col>
				</v-row>
				<v-row class="mb-3">
					<v-col md="3" class="py-0">
						<label for="">Attachments</label>
					</v-col>
					<v-col md="9" class="py-0">
						<template v-for="(file, index) in files">
							<v-row class="d-flex" :key="index">
								<v-col md="5" class="pr-1 py-1">
									<v-file-input
										:id="`document-file-${index}`"
										placeholder="Select File"
										outlined
										prepend-icon=""
										prepend-inner-icon="mdi-attachment"
										hide-details
										v-model="file.file"
										v-on:change="updateFile(index, $event)"
										v-on:click:clear="updateFile(index, $event)"
									></v-file-input>
								</v-col>
								<v-col md="5" class="pl-1 pr-2">
									<TextInput
										:id="`document-name-${index}`"
										v-model="file.name"
										hideTopMargin
										hide-details
										placeholder="File Name"
										:suffix="file.suffix"
										:customStyle="{ minHeight: '30px' }"
									></TextInput>
								</v-col>
								<v-col md="2" class="d-flex px-0">
									<v-btn
										:disabled="files.length < 2"
										v-on:click="removeFile(index)"
										color="red white--text"
										class="px-0"
										style="min-width: 45px !important"
										icon
									>
										<v-icon>mdi-delete</v-icon>
									</v-btn>
									<v-btn
										v-if="index == files.length - 1"
										color="blue darken-4 white--text"
										tile
										icon
										class="px-0"
										style="min-width: 45px !important"
										:disabled="files.length > 4"
										depressed
										v-on:click="addMore()"
									>
										<v-icon>mdi-plus</v-icon>
									</v-btn>
								</v-col>
								<!-- <v-col md="1" class="px-0 mt-3" v>
									
								</v-col> -->
							</v-row>
						</template>
					</v-col>
				</v-row>
			</v-form>
		</template>
		<template v-slot:action>
			<v-btn
				class="white--text"
				:loading="pageLoading"
				:disabled="!formValid || pageLoading"
				depressed
				color="blue darken-4"
				tile
				v-on:click="onSubmit()"
			>
				Save
			</v-btn>
			<v-btn depressed tile :disabled="pageLoading" v-on:click="$emit('close', true)"> Close </v-btn>
		</template>
	</Dialog>
</template>
<script>
import { mapGetters } from "vuex";
import Dialog from "@/view/components/Dialog";
import TextInput from "@/view/components/TextInput";
/* import DatePicker from "@/view/components/DatePicker"; */
import { POST, GET } from "@/core/services/store/request.module";
/* import MomentJS from "moment-timezone"; */
import { SET_MESSAGE, SET_ERROR } from "@/core/services/store/common.module";
import MainMixin from "@/core/mixins/main.mixin.js";
import { toSafeInteger } from "lodash";
import TextAreaInput from "@/view/components/TextAreaInput";
import ExtendReasons from "../components/ExtendReasons.vue";

export default {
	mixins: [MainMixin],
	name: "extend-checkout-template",
	title: "Extend Checkout Template",
	props: {
		CancelDialog: {
			type: Boolean,
			default: false,
		},
		projectData: {
			type: Object,
			default() {
				return new Object();
			},
		},
		projectId: {
			type: Number,
			default: 0,
		},
	},
	data() {
		return {
			formValid: true,
			pageLoading: false,

			cancelProject: {
				reason: null,
				remark: null,
			},
			reasonList: [],
			files: [
				{
					file: null,
					name: null,
				},
			],
		};
	},
	watch: {},
	components: {
		Dialog,
		/* 	DatePicker, */
		TextInput,
		TextAreaInput,
		ExtendReasons,
	},

	methods: {
		updateFile(index, file) {
			if (file && file.name) {
				this.files[index].name = file.name.split(".").slice(0, -1).join(".");
				this.files[index].suffix = `.${file.name.split(".").pop()}`;
			} else {
				this.files[index].name = null;
				this.files[index].suffix = null;
			}
		},
		addMore() {
			this.files.push({
				file: null,
				name: null,
			});
		},

		removeFile(index) {
			this.files.splice(index, 1);
			if (this.files.length < 1) {
				this.addMore();
			}
		},
		getSettings() {
			const _this = this;
			_this.$store
				.dispatch(GET, {
					url: "project-setting",
				})
				.then((data) => {
					_this.reasonList = data.reason;
				})
				.catch((error) => {
					console.log({
						error,
					});
				})
				.finally(() => {
					_this.pageLoading = false;
				});
		},

		onSubmit() {
			const _this = this;

			const formErrors = _this.validateForm(_this.$refs.extendCheckoutForm);
			if (formErrors.length) {
				_this.$store.commit(SET_ERROR, _this.errors.concat(formErrors));
				return false;
			}

			if (!_this.$refs.extendCheckoutForm.validate()) {
				return false;
			}

			this.pageLoading = true;

			let formData = new FormData();
			if (this.projectId && this.projectId) {
				formData.append("project", this.projectId ? toSafeInteger(this.projectId) : 0);
			}

			/* if (this.cancelProject && this.cancelProject.select_type) {
				formData.append("select_type", this.cancelProject.select_type ? this.cancelProject.select_type : null);
			}
			if (this.cancelProject && this.cancelProject.time_period) {
				formData.append("time_period", this.cancelProject.time_period ? this.cancelProject.time_period : null);
			} */

			if (this.cancelProject && this.cancelProject.reason) {
				formData.append("reason", this.cancelProject.reason ? this.cancelProject.reason : null);
			}
			if (this.cancelProject && this.cancelProject.remark) {
				formData.append("remark", this.cancelProject.remark ? this.cancelProject.remark : null);
			}

			for (let i = 0; i < this.files.length; i++) {
				if (this.files && this.files[i] && this.files[i].file) {
					formData.append(`file[${i}][file]`, this.files[i].file);
				}
				if (this.files && this.files[i] && this.files[i].name) {
					formData.append(`file[${i}][name]`, this.files[i].name);
				}
			}

			let requestTYPE = POST;
			let requestURL = `project-cancel/${this.projectId}`;

			this.$store
				.dispatch(requestTYPE, {
					url: requestURL,
					data: formData,
				})
				.then(() => {
					this.$store.commit(SET_MESSAGE, [
						{ model: true, message: "Success ! Project Cancelled successfully." },
						this.$emit("success", true),
					]);

					this.$refs.form.reset();
				})
				.catch((error) => {
					console.log({ error });
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
	},

	computed: {
		...mapGetters(["errors"]),
	},
	mounted() {
		this.getSettings();
	},
};
</script>
